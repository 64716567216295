<template>
	<span v-if="dictItem">
		<a-tag v-if="props.type==='tag'" :color="dictItem.color">
			{{ dictItem.name }}
		</a-tag>
		<span v-else :style="`color:${dictItem.color}`">
			{{ dictItem.name }}
		</span>
	</span>
	<span v-else> - </span>
</template>

<script setup name="LDict">
import {  onMounted, computed, reactive, watchEffect } from 'vue'
import dict from '@/utils/dict'

const props = defineProps({
	//Text 或者 Tag
	type: {
		type: String,
		default: "tag",
		required: false
	},
	code: {
		type: String,
		required: true
	},
	value: {
		type: String,
		required: true
	}
})

const dictItem = ref({})

watchEffect(() => {
  dictItem.value = dict.getItem(props.code,props.value)
})

onMounted(() => {
  dictItem.value = dict.getItem(props.code,props.value)
})
</script>
