<template>
  <LSelect v-model:value="data.internalValue" :placeholder="props.placeholder" listUrl="/sys/user/selector" detailUrl="/sys/user/display"
    :pagination="true" :multiple="props.multiple" :maxTagCount="props.maxTagCount" @change="onValueChange" :disabled="disabled">
    <template v-slot:option="{ item }">
      {{ item.name }}
      <span>{{ '- ' + item.account }}</span>
      <span v-if="item.phone"> {{ '- ' + item.phone }}</span>
    </template>
  </LSelect>
</template>

<script setup name="LUserSelect">
import LSelect from '@/components/Leo/LSelect.vue'

const props = defineProps({
  maxTagCount: Number,
  display: [Array, String],
  //默认多选
  multiple: {
    type: Boolean,
    required: false,
    default: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  value: {
    type: [Array, String],
    required: false,
    default: () => { },
  },
  placeholder: {
    type: String,
    required: false,
    default: '请选择用户',
  },
})

const emits = defineEmits(["update:value","change"])

const data = reactive({
  internalValue: props.value,
  loading: false,
  intenalOptions: [],
  size: 5,
  current: 1,
  total: 0,
  searchKey: '',
  selectedValues:[]
})

watch(() => props.value, (newValue) => {
	 data.internalValue = newValue
})

const onValueChange = (selectedValues) => {
  emits('update:value', selectedValues)
}

</script>
<style scoped></style>