<template>
  <!-- 分页 -->
  <a-select v-if="props.pagination" labelInValue :value="data.internalValue" :maxTagCount="props.maxTagCount"
    @change="onValueChange" :disabled="props.disabled" :mode="mode" :placeholder="props.placeholder" :filterOption="false"
    allowClear style="width: 100%" :showSearch="props.showSearch" :showArrow="true" @search="onSearch"
    optionLabelProp="label">
    <template #dropdownRender="{ menuNode: menu }">
      <v-nodes :vnodes="menu" />
      <div v-if="maxPage > 1" @mousedown="(e) => e.preventDefault()">
        <a-divider style="margin: 0" />
        <div style="padding: 8px; cursor: pointer; text-align: center">
          <a-pagination style="" size="small" hideOnSinglePage :default-current="1" :showQuickJumper="false"
            :total="data.total" :pageSize="data.size" @change="onPageChange" />
        </div>
      </div>
    </template>
    <template #notFoundContent>
      <a-spin v-if="data.loading" size="small" />
    </template>

    <a-select-option v-for="(item) in data.intenalOptions" :key="item[optionKeyProp]" :value="item[optionKeyProp]"
      :label="item[optionLabelProp]">
      <slot name="option" :item="item">
        {{ item.name }}
      </slot>
    </a-select-option>
  </a-select>

  <!-- 不分页 -->
  <a-select v-else :value="value" @change="onValueChange" :disabled="disabled" :mode="mode" :placeholder="placeholder"
    :getPopupContainer="getPopupContainer" optionFilterProp="children" allowClear style="width: 100%"
    :showSearch="showSearch" :showArrow="true" @search="onSearch" optionLabelProp="label">
    <template #notFoundContent>
      <a-spin v-if="data.loading" size="small" />
    </template>
    <a-select-option v-for="(item) in data.intenalOptions" :key="item[optionKeyProp]" :value="item[optionKeyProp]"
      :label="item[optionLabelProp]">
      <slot name="option" :item="item">
        {{ item.name }}
      </slot>
    </a-select-option>
  </a-select>
</template>

<script setup name="LSelect">
import { onMounted, computed, reactive, watchEffect, watch } from 'vue'
import request from '@/utils/request'
import { collect } from '@/utils/string'

const props = defineProps({
  maxTagCount: Number,
  placeholder: String,
  //选项label对应数据的属性
  optionLabelProp: {
    type: String,
    required: false,
    default: 'name',
  },
  //选项key对应数据的属性
  optionKeyProp: {
    type: String,
    required: false,
    default: 'id',
  },
  disabled: Boolean,
  pagination: {
    type: Boolean,
    required: false,
    default: false,
  },
  showSearch: {
    type: Boolean,
    required: false,
    default: true,
  },
  value: {
    type: [String, Array],
    default: undefined,
    required: false,
  },
  type: String,
  options: Array,
  popContainer: {
    type: String,
    default: '',
    required: false,
  },
  multiple: {
    type: Boolean,
    required: false,
    default: false,
  },
  listUrl: '',
  detailUrl: '',
})

const VNodes = (_, { attrs }) => {
  return attrs.vnodes;
}

const emits = defineEmits(["update:value", "change"])

const data = reactive({
  internalValue: undefined,
  loading: false,
  intenalOptions: [],
  size: 5,
  current: 1,
  total: 0,
  searchKey: '',
  selectedValues: []
})


const maxPage = computed(() => {
  return Math.ceil(data.total / data.size)
})

const mode = computed(() => {
  return props.multiple ? "multiple" : "default"
})

onMounted(() => {
  if (props.listUrl) {
    loadData()
  }
  if (props.pagination) {
    init()
  }
})

// 异步情况下，子组件mounted可能早于父组件传递props，导致mounted无法获取props中的参数
watch(() => props.value, () => {
  if (props.pagination) {
    init()
  }
})


const loadData = () => {
  data.loading = true
  let params = {
    searchKey: data.searchKey,
    pageNo: data.current,
    pageSize: data.size
  }
  request.get(props.listUrl, params).then((res) => {
    data.loading = false
    if (props.pagination) {
      data.total = res.total
      data.intenalOptions = res.records
    } else {
      data.intenalOptions = res
    }
  })
}

const reflesh = () => {
  data.internalValue = undefined;
}

const init = () => {
  let newValue = props.value
  if (!newValue || newValue.length == 0) {
    data.internalValue = props.pagination ? [] : undefined;
    return;
  }
  //在分页情况下，获取展示字段
  if (props.pagination && newValue) {
    if (props.multiple && newValue.length > 0) {
      console.log("newValue=", newValue)
      let existLableValues = []
      //需要加载远程选项的值
      let notExistId = []
      console.log("internalLabelValue=", data.internalValue)
      if (data.internalValue) {
        newValue.forEach(id => {
          let exist = data.internalValue.find(labelValue => labelValue.value === id)
          if (exist) {
            existLableValues.push(exist)
          } else {
            notExistId.push(id);
          }
        });
      } else {
        notExistId = newValue;
      }
      console.log("existLableValues=", existLableValues)
      console.log("notExistId=", notExistId)
      if (notExistId.length > 0) {
        request.get(props.detailUrl, { ids: notExistId.join(",") }).then(res => {
          let remoteLabelValues = res.map((single) => {
            return { label: single[props.optionLabelProp], value: single[props.optionKeyProp] ,...single}
          })
          //合并远程加载和本地的值
          data.internalValue = existLableValues.concat(remoteLabelValues)
        })
      }

    } else if (!props.multiple && props.value) {
      if (props.value === data.internalValue?.id) {
        //相关值已有对应的选项
        return;
      }
      request.get(props.detailUrl, { ids: props.value }).then(res => {
        let display = res[0];
        if (display) {
          data.internalValue = { label: display[props.optionLabelProp], key: display[props.optionKeyProp],...display }
        }
      })
    }
  }
}

const onSearch = (value) => {
  data.searchKey = value
  data.current = 1
  loadData()
}

const onPageChange = (current, pageSize) => {
  data.current = current
  data.size = pageSize
  loadData()
}

const onValueChange = (selectedValues,record) => {
  //搜索的情况下选中需要清空
  if (data.searchKey) {
    data.searchKey = ""
    data.current = 1
    loadData()
  }
  //  data.internalValue = selectedValues
  //清空
  if (!selectedValues) {
    emits("change", selectedValues)
    emits("update:value", selectedValues)
    return;
  }
  if (props.pagination) {
    data.internalValue = selectedValues
    let value
    if (Array.isArray(selectedValues)) {
      value = collect(selectedValues, 'key')
    } else {
      value = selectedValues.key
    }
    emits("change", value,record)
    emits("update:value", value)
  } else {
    emits("change", unref(selectedValues),record)
    emits("update:value", unref(selectedValues))

  }
}

const getPopupContainer = (node) => {
  if (!props.popContainer) {
    return node.parentNode
  } else {
    return document.querySelector(this.popContainer)
  }
}

// watchEffect(()=>{
// let value = props.value;
//  if (!value || (value instanceof Array && value.length == 0)) {
//       reflesh()
//     }
//     else {
//       init()
//     }
// })

defineExpose({
  //  ...toRefs(data)
})

</script>
