<template>
	<div class="clearfix">
		<a-upload
			list-type="picture-card"
			:file-list="data.fileList"
			:multiple="true"
			@preview="handlePreview"
			:disabled="props.disabled"
			@change="onChange"
			accept=".jpg, .jpeg, .png"
			:beforeUpload="
				(file, fileList) => {
					return false
				}
			"
		>
			<div v-if="!disabled && value.length < max">
				<plus-outlined :style="{ fontSize: '20px' }" />
				<!-- <div class="ant-upload-text">上传图片</div> -->
			</div>
		</a-upload>
		<a-modal :visible="data.previewVisible" :footer="null" @cancel="handleCancel">
			<img alt="example" style="width: 100%" :src="data.thumbUrl" />
		</a-modal>
	</div>
</template>
<script setup name="LImgUpload">
const props = defineProps({
	disabled: {
		type: Boolean,
		default: false,
		required: false
	},
	value: {
		type: Array,
		default: () => [],
		required: false
	},
	max: {
		type: Number,
		default: 5,
		required: false
	},
	//双向绑定的值类型，id ，url ，raw
	type: {
		type: String,
		required: true
	},
	width: {
        type: Number,
        default: 100,
    },
 	 height: {
        type: Number,
        default: 100,
    },
})

const emits = defineEmits(['update:value'])

const data = reactive({
	previewVisible: false,
	thumbUrl: '',
	annexIds: [],
	fileList: props.value
})

watch(
	() => props.value,
	() => {
		data.fileList = props.value
	}
)

const handleCancel = () => {
	data.previewVisible = false
}
const handlePreview = (file) => {
	data.thumbUrl = file.thumbUrl || file.url
	data.previewVisible = true
}
const onChange = (info) => {
	data.value = info.fileList
	emits('update:value', info.fileList)
}
</script>
<style lang="less">
.clearfix {
	margin-top: 15px;
	margin-left: 15px;
}

.avatar-uploader > .ant-upload {
  width: 233px;
  height: 228px;
}

.ant-upload-list-picture-card-container,
.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload.ant-upload-select-picture-card {
	width: v-bind("props.width+'px'");
	height: v-bind("props.height+'px'");
  max-width: v-bind("props.width+'px'");
  max-height: v-bind("props.height+'px'");
	padding: 0px;
}
</style>
