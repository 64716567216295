/**
 *  Copyright [2022] [https://www.xiaonuo.vip]
 *	Snowy采用APACHE LICENSE 2.0开源协议，您在使用过程中，需要注意以下几点：
 *	1.请不要删除和修改根目录下的LICENSE文件。
 *	2.请不要删除和修改Snowy源码头部的版权声明。
 *	3.本项目代码可免费商业使用，商业使用请保留源码和相关描述文件的项目出处，作者声明等。
 *	4.分发源码时候，请注明软件出处 https://www.xiaonuo.vip
 *	5.不可二次分发开源参与同类竞品，如有想法可联系团队xiaonuobase@qq.com商议合作。
 *	6.若您的项目无法满足以上几点，需要更多功能代码，获取Snowy商业授权许可，请在官网购买授权，地址为 https://www.xiaonuo.vip
 */
// 静态路由配置
const routes = {
	// 默认模块，仅限于后端未添加任何单页配置，用此路由
	module: [
		{
			id: '01',
			name: 'homeModule',
			path: '/homeModule',
			component: '',
			meta: {
				title: '默认',
				type: 'module',
				icon: 'bank-outlined'
			},
			children: []
		}
	],
	// 默认首页、用户中心
	menu: [
		{
			id: '001',
			name: 'index',
			path: '/index',
			component: 'index/index',
			meta: {
				title: '首页',
				type: 'menu',
				icon: 'bank-outlined',
				affix: true
			},
			children: []
		},
		{
			id: '002',
			name: 'usercenter',
			path: '/usercenter12',
			component: 'userCenter/index',
			meta: {
				title: '用户中心',
				type: 'menu',
				hidden: true
			},
			children: []
		}
	]
}

export default routes
