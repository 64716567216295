// 补充异步加载

<script>
import { CheckboxGroup } from 'ant-design-vue'
import { h } from 'vue'
import { onMounted, computed, reactive, watch, render } from 'vue'
import dict from '@/utils/dict'

export default defineComponent({
	inheritAttrs: true,
	props: {
		code: String
	},
	setup(props,{attrs}) {
		const loadDictData = () => {
			return dict.getSelectItems(props.code)
		}
		return () => {
			return h(CheckboxGroup,{
				options:loadDictData()
			});
		}
	}
})
</script>