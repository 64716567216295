 /**
  * @author LeoStark
  * @date 2023-03-02
  * 自定义组件
  */
 import LSelect from './components/Leo/LSelect.vue'
 import LDict from './components/Leo/LDict.vue'
 import LDictSelect from './components/Leo/LDictSelect.vue'
 import LDictRadio from './components/Leo/LDictRadio.vue'
 import LDictCheckBox from './components/Leo/LDictCheckBox.vue'
 import LUserSelect from './components/Leo/LUserSelect.vue'
 import LImgUpload from './components/Leo/LImgUpload.vue'
 import LTreeSelect from './components/Leo/LTreeSelect.vue'
 import LImgViewer from './components/Leo/LImgViewer.vue'
 import print from 'vue3-print-nb'


export default {
	install(app) {
		// 注册常用组件
		app.component('LSelect', LSelect)
		app.component('LDict', LDict)
		app.component('LDictSelect', LDictSelect)
		app.component('LDictRadio', LDictRadio)
		app.component('LDictCheckBox', LDictCheckBox)
		app.component('LUserSelect', LUserSelect)
		app.component('LTreeSelect', LTreeSelect)
		app.component('LImgViewer', LImgViewer)
		app.component('LImgUpload', LImgUpload)
		app.use(print)
	}
}
