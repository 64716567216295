// 补充异步加载

<script>
import { TreeSelect } from 'ant-design-vue'
import { treeSelectProps } from 'ant-design-vue/es/tree-select/index.js'
import { h } from 'vue'
import { defineComponent, defineEmits, defineProps, onMounted, computed, reactive, watch, render } from 'vue'
import request from '@/utils/request'
import { collect } from '@/utils/string'

export default defineComponent({
	inheritAttrs: true,
	props: {
		url: String
	},
	setup(props, { attrs }) {
		const emits = defineEmits(['update:value', 'change'])

		// const change = (value, label, extra) => {
		// 	emits('update:value', value)
		// }
		//回显
		let treeData = ref()
		request.get(props.url).then((res) => {
			treeData.value = res
		})

		return () => {
			return h(TreeSelect, {
				treeData: treeData.value,
				fieldNames: { children: 'children', label: 'title', value: 'id' }
			})
		}
	}
})
</script>