import tool from '@/utils/tool'
import dictApi from '@/api/dev/dictApi'

/**
 * 重新加载字典缓存
 */
export async function reflesh() {
	let data = await dictApi.dictGlobal();
	tool.data.set('DICT_TYPE_TREE_DATA', data)
}


/**
 * 获取数据字典项
 */
export function getItem(dictCode, value) {
	let existItem
	if(value&&dictCode){
		let dict = getItems(dictCode);
		if (dict) {
			//字典项编码不区分大小写
			value = value.toLowerCase()
			dict.forEach(item => {
				if (item.code.toLowerCase() === value) {
					existItem = item;
				}
			})
		}
	}
	return existItem;
}

/**
* 获取数据字典
*/
export function getItems(dictCode) {
	let dictCodeLower = dictCode.toLowerCase()
	const dict = tool.dictDataAll()
	if (!dict) {
		return []
	}
	return dict[dictCodeLower] || [];
}


/**
 * 获取数据字典下拉框格式数据
 */
export function getSelectItems(dictCode) {
	let dict = getItems(dictCode);
	if (!dict) {
		return []
	}
	return dict.map((item) => {
		return {
			value: item['code'],
			label: item['name']
		}
	})
}


export default {
	getItem,
	getItems,
	getSelectItems,
	reflesh
}
